import Contact from "../components/contact_us/index";
import Head from "next/head";
// import HomeAnimation from "../components/home/HomeAnimation";
import Logo from "../components/home/Logo";
import OurBlog from "../components/homepage/ourblog/OurBlog";
import Projects from "../components/Homepage_Projects/HomePageSlider";
import ProductsSection from "../components/our_products";
import OurVision from "../components/home/OurVision";
import ScrollAnimation from "../components/homepage/scrollAnimatin/ScrollAnimation.js";
import axios from "../Axios";
import Scroll from "../components/homepage/progress/Scroll";
import { useEffect } from "react";
import ServiceWorkerUnregister from "../components/sw-unregister";

const Home = ({ allBlogsData, projectsData, allProductsData, animation }) => {
  // remove server worker
  useEffect(() => {
    ServiceWorkerUnregister();
  }, []);

  return (
    <div>
      <Head>
        <title>Ascot - Innovative Solutions for Business Growth</title>
        <meta
          name="title"
          property="og:title"
          content="Ascot - Trusted IT Solutions Provider for Driving Business Growth "
          key="title"
        />
        <meta
          name="description"
          property="og:description"
          content="Ascot is a trusted IT solutions provider, offering innovative  IT solutions that exceed your expectations, drive business growth and meet your specific needs."
        />
        <meta
          property="og:image"
          name="logo"
          content="favicon.ico"
          href="favicon.ico"
        />
        <meta property="og:image" content="https://www.ascot.ws/favicon.ico" />


        <link rel="icon" href="favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:type" content="website" />
      </Head>

      <div className="xlg:pt-[150px]">
        <Scroll />
        <Logo animation={animation} />
        <OurVision />
        <ProductsSection products={allProductsData} />
        <ScrollAnimation />
        <OurBlog allBlogs={allBlogsData} />
        <Projects projects={projectsData} />
        <Contact />
      </div>
    </div>
  );
};
export default Home;

export async function getServerSideProps(context) {
  const lang = context.locale;

  try {
    const blog = await axios.get(`user/blog/all-blogs?lang=${lang}`);
    const allBlogsData = blog?.data?.result.blogs;
    const project = await axios.get(`user/project/slider?lang=${lang}`);
    const projectsData = project?.data?.result;

    const product = await axios.get(`user/products/all-Products/${lang}`);
    const allProductsData = product?.data?.result;

    const animate = await axios.get(`user/animation`);
    const animation = animate?.data.result;

    return {
      props: {
        allBlogsData,
        projectsData,
        allProductsData,
        animation,
        messages: require(`../locales/${lang || "en"}.json`),
      },
    };
  } catch (error) {
    return {
      props: { data: false },
    };
  }
}
