import React from "react";
import Image from "next/image";
import Link from "next/link";

export default function index({ products, t }) {
  return (
    <div className="mx-auto -z-20 flex  justify-center items-center max-w-[90%]   pt-16 pb-16">
      <div className="flex flex-wrap z-10 max-w-[300px] gap-[17px] ">
        {products?.map((item, index) => {
          return (
            <div className="bg-gradient-to-br mx-auto -z-10 max-w-[100px]  min-w-[100px]  h-[100px] from-[#5f5f86] via-[#251f59] to-[#5f5f86] bg-opacity-10 rounded-[10px] p-[1px] col-span-6 mds:col-span-4 sm:col-span-4">
              <div className="h-full rounded-[8px] bg-[#010140]">
                <div
                  style={{
                    background:
                      "linear-gradient(135.41deg, rgba(255, 255, 255, 0.15) 1.97%, rgba(255, 255, 255, 0.07) 37.35%, rgba(255, 255, 255, 0) 49.99%, rgba(255, 255, 255, 0.02) 64.14%, rgba(255, 255, 255, 0.2) 99.02%)",
                  }}
                  className="h-full rounded-[8px] "
                >
                  <Link href={`services/${item._id}`}>
                    <div
                      style={{ borderRadius: "10px" }}
                      className="h-[100px] z-20 flex flex-col justify-center items-center text-center"
                    >
                      <div className="flex justify-center items-center">
                        <Image
                          src={item?.icon}
                          width={"34px"}
                          height={"34px"}
                          alt="single product"
                          className="brightness-0 invert"
                        />
                      </div>
                      <p className="leading-[12px] my-3 text-[12px] font-[510] text-[#F6F6F6] px-1 text-center">
                        {item?.iconTitle}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
