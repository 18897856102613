import React from "react";
import ImageF from "./ImageF";
import Image from "next/image";
const Responsive_logo = ({ changeImage, animation }) => {
  const { bottomLeft, bottomRight, projectLogo, topLeft, topRight } =
    animation?.[changeImage];
  return (
    <>
      <div className={`absolute left-[17%] top-[30%] mds:hidden block`}>
        <Image
          height={"70.37px"}
          width={"145.51px"}
          src={projectLogo}
          alt="ascot images"
          className="object-scale-down"
        />
      </div>
      <div className="mds:hidden block">
        <ImageF
          changeImage={changeImage}
          className={"absolute"}
          src={topLeft}
          position={{ start: { x: 100, y: 50 }, end: { x: -40, y: -35 } }}
        />
        <ImageF
          changeImage={changeImage}
          className={"absolute"}
          src={topRight}
          position={{ start: { x: 0, y: 0 }, end: { x: 140, y: -25 } }}
        />
        <ImageF
          changeImage={changeImage}
          className={"absolute"}
          src={bottomRight}
          position={{ start: { x: 100, y: 50 }, end: { x: 140, y: 130 } }}
        />
        <ImageF
          changeImage={changeImage}
          className={"absolute"}
          src={bottomLeft}
          position={{ start: { x: 100, y: 50 }, end: { x: -70, y: 110 } }}
        />
      </div>
    </>
  );
};

export default Responsive_logo;
