import React from "react";
import ProductsSlider from "./ProductsSlider";
import ProductsMobile from "./ProductsMobile";
import { useTranslations } from "next-intl";
import SectionTitle from "../../components/SectionTitle/index";

export default function index({ products }) {
  const t = useTranslations("services");
  return (
    <div
      id="Products"
      className="bg-[#010140] pt-5 -pb-10   relative z-10 overflow-hidden"
    >
      <div
        style={{
          background: "#0AF0DD",
          opacity: "0.33",
          filter: "blur(60px)",
        }}
        className="-z-10 absolute rounded-full  -top-[85%] -left-[65%] lg:-top-[410px] lg:-left-32  h-[571.66px] w-[595px]"
        id="titleAnimation"
      ></div>
      <div className="mx-auto max-w-[90rem] px-4  sm:px-6 lg:px-0">
        {/* <div className=" xl:m-auto lg:mx-3 mx-3 md:mx-3 max-w-[90rem]"> */}
        <div className="  text-center justify-center  items-center flex flex-col  pt-[2rem]">
          <div className="mx-auto max-w-[90rem]  px-4 pb-5 sm:px-6  lg:px-0">
            <SectionTitle title={t("our_services")} white={true} />
          </div>
          <div className="lg:w-[60%] w-[93%] text-center justify-center">
            <p className=" text-[#F6F6F6] mt-9 font-[400] leading-[20px] text-[16px] md:text-[20px] md:leading-[28px]">
              {t("expertise1")}
              <br />
              {t("expertise2")}
            </p>
            <p className=" text-[#F6F6F6] mt-9 font-[400] leading-[20px] text-[16px] md:text-[20px] md:leading-[28px]"></p>
          </div>
        </div>
      </div>
      <div id="products" className="hidden z-10  lg:block">
        <ProductsSlider products={products} t={t} />
      </div>
      <div className=" lg:hidden">
        <ProductsMobile products={products} t={t} />
      </div>
      <div
        style={{
          background: "#951B81",
          opacity: "0.43",
          filter: "blur(60px)",
        }}
        className="hidden lg:block lg:absolute rounded-full -z-10 -bottom-[400px] left-64 h-[509.75px] w-[527px]"
        id="titleAnimation"
      ></div>
      <div
        style={{
          background: "#BE72F3",
          opacity: "0.45",
          filter: "blur(60px)",
          transform: "matrix(0.79, -0.62, 0.64, 0.77, 0, 0)",
        }}
        className="absolute rounded-full -z-10 -bottom-[100px] -left-[450px] lg:bottom-[40px] lg:-right-[470px] lg:left-auto h-[540.26px] w-[535.48px]"
        id="titleAnimation"
      ></div>
    </div>
  );
}
