import { motion, useAnimation } from "framer-motion";
import { useRouter } from "next/router";
import Button from "../btn/buttonCustom/index";
import { useTranslations } from "next-intl";
export default function TitleAnimition({
  changeImage,
  title,
  buttonText,
  buttonLink,
}) {
  // export default function TitleAnimition({ changeImage, animation }) {
  const t = useTranslations("Home.animation");
  const router = useRouter();
  return (
    <div dir={router.locale == "en" ? "ltr" : "rtl"}>
      <motion.div
        key={changeImage + router.locale}
        initial={{ opacity: 0 }}
        animate={{
          y: [100, 0, 0, 0, -100],
          opacity: [0, 1, 1, 1, 0],
          transition: {
            duration: 5,
          },
        }}
      >
        <div className="mds:text-[48px] text-[#010140] h-auto  m-auto  text-[32px] leading-[40px] max-w-[554px] w  mds:leading-[65px] w-[90%] mt-[30px] font-semibold ">
          <h1 dangerouslySetInnerHTML={{ __html: title[router.locale] }} />
        </div>

        <div className="pl-[20px] xlg:pt-[50px] pt-[30px] w-[190px]">
          <Button txt={buttonText[router.locale]} href={buttonLink} />
        </div>
      </motion.div>
    </div>
  );
}
