import React from "react";
export const useScrollLock = ({ preventDefault }) => {

  const lockScroll = React.useCallback(() => {
    document.documentElement.style.scrollBehavior = 'auto';
    const TopScroll = window.pageYOffset || document.documentElement.scrollTop;
    // if scroll happens, set it to the previous value
    window.onscroll = function () {
      window.scrollTo(0, TopScroll);
    };

    document.body.addEventListener('touchmove', preventDefault, { passive: false });
    document.body.addEventListener('mousewheel', preventDefault, { passive: false });

    return () => {
      document.body.removeEventListener('scroll', preventDefault, { passive: false });
      document.body.removeEventListener('touchmove', preventDefault, { passive: false });
      document.body.removeEventListener('mousewheel', preventDefault, { passive: false });

      function preventDefault(e) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      // document.documentElement.removeEventListener("scroll", preventScroll);
      // window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.documentElement.style.scrollBehavior = 'smooth';

    window.onscroll = function () { };

    document.body.removeEventListener('touchmove', preventDefault, { passive: false });
    document.body.removeEventListener('mousewheel', preventDefault, { passive: false });
  }, []);
  return {
    lockScroll,
    unlockScroll,
  };
}
