import React from "react";

export default function RightArrow() {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7397 12.3531C20.617 12.3531 20.4848 12.3531 20.3621 12.3531C11.7343 12.3531 10.3238 12.3531 1.69593 12.3531C0.742521 12.3531 0.138382 11.9233 0.015666 11.1583C-0.10705 10.4276 0.506529 9.70552 1.31834 9.62816C1.47882 9.61096 1.64873 9.61956 1.8092 9.61956C10.4088 9.61956 11.772 9.61956 20.3716 9.61956C20.4943 9.61956 20.617 9.61956 20.7586 9.61956C20.7208 9.45623 20.5981 9.40466 20.5132 9.31869C17.9928 7.01493 15.4629 4.71976 12.9331 2.416C12.0647 1.62516 12.3384 0.413103 13.4617 0.0778541C14.0659 -0.102665 14.5945 0.0348734 15.0476 0.447488C15.7839 1.11799 16.5107 1.77989 17.247 2.45039C19.9657 4.92607 22.6843 7.40176 25.4029 9.87744C26.2053 10.6081 26.1959 11.3646 25.3935 12.0952C21.9574 15.2242 18.5214 18.3532 15.0853 21.4822C14.6511 21.8776 14.1603 22.1011 13.5278 21.955C12.395 21.6885 11.9891 20.4593 12.7915 19.6856C13.339 19.1527 13.9243 18.6455 14.4906 18.1297C16.5013 16.2988 18.512 14.4678 20.5226 12.6454C20.6076 12.568 20.7114 12.5165 20.8058 12.4563C20.7869 12.4219 20.7586 12.3875 20.7397 12.3531Z"
        fill="#F6F6F6"
      />
    </svg>
  );
}
