import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
import CustomBtnComponent from "../btn/buttonCustom";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import Link from "next/link";
import SectionTitle from "../SectionTitle";
import axios from "../../Axios";

// create slider tags
const sliderTags = (title) => {
  return (
    <Link href={`/projects/filter/${title}`}>
      <span className="cursor-pointer w-fit px-4  h-fit py-2  bg-[#010140] bg-opacity-5 rounded-[22px] flex items-center justify-center text-[#3A3A3A] text-[10px] lg:text-[12px] font-[510]">
        {title}
      </span>
    </Link>
  );
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  pauseOnFocus: true,
  pauseOnDotsHover: true,
};

function HomePageSlider({ projects }) {
  const { locale } = useRouter();

  // select active slider based on languag
  useEffect(() => {
    const EnSlide = document.querySelector(".slick-dots li:first-child div");
    const ArSlide = document.querySelector(".slick-dots li:last-child div");
    locale == "en" ? EnSlide?.click() : ArSlide?.click();
  }, [locale]);

  const t = useTranslations("homepageProjects");

  const [activeSlide, setActiveSlide] = useState(0);
  const beforeChange = (current, next) => {
    setActiveSlide(next);
  };

  const customPaging = (i) => {
    if (i === activeSlide) {
      return (
        <div className="w-[22px] h-[22px] rounded-full border-[#BE72F3] border-[1px] border-opacity-20 flex justify-center items-center">
          <div className="w-[16px] h-[16px] rounded-full border-[#BE72F3] border-[1px] border-opacity-50 flex justify-center items-center">
            <div className="w-2 h-2 md:w-[10px] md:h-[10px] rounded-full bg-[#951B81] " />
          </div>
        </div>
      );
    }

    return (
      <div className="bg-black bg-opacity-20 w-2 h-2 md:w-[10px] md:h-[10px] rounded-full" />
    );
  };

  return (
    <div
      id="Projects"
      className="p-0  w-full bg-[#010140] bg-opacity-[0.04] xsm:skew-y-[4deg] xsm:[&>*]:-skew-y-[4deg]  md:skew-y-2 md:[&>*]:-skew-y-2  relative my-10 py-12"
    >
      <div className=" flex flex-col items-center pt-12">
        <h2 className=" pb-9 lg:pb-14">
          <SectionTitle title={t("title")} />
        </h2>
      </div>

      <Slider
        {...settings}
        beforeChange={beforeChange}
        customPaging={customPaging}
        rtl={locale == "ar" ? true : false}
      >
        {projects?.map((item, i) => {
          return (
            <div
              key={i}
              className=" flex flex-col mx-auto homePageCarousel pt-3 "
              dir={locale == "ar" ? "rtl" : "ltr"}
            >
              <div className="flex flex-col items-start md:flex-row-reverse md:justify-between md:gap-10">
                {/* image */}
                <div className="mb-5 w-[calc(100%-20px)] md:w-full mx-auto">
                  <div className="relative w-full mx-auto sm:w-[320px] lg:w-[462px] lg:h-[330px]  h-[180px] z-10  ">
                    <Image
                      src={item.sliderImage}
                      alt="project description"
                      layout="fill"
                      objectFit="cover"
                      className=" rounded-[10px]"
                      loading="lazy"
                    />

                    <div className="-z-10 absolute -right-2 -top-2 lg:right-[-11px] lg:top-[-12px] w-[102px] lg:w-[183px] h-[102px] lg:h-[183px]">
                      <Image
                        src="/career/border.png"
                        alt="project description"
                        layout="fill"
                        loading="lazy"
                      />
                    </div>

                    <div className="-z-10 absolute -left-1 -bottom-1 lg:left-[-8px] lg:bottom-[-10px] w-[102px] lg:w-[183px] h-[102px] lg:h-[183px]">
                      <Image
                        src="/career/border.png"
                        alt="project description"
                        layout="fill"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                {/* details  */}
                <div className="mb-[68px] lg:mb-[90px]">
                  {/* upper tags  */}
                  <div>
                    <h3 className="font-[590] text-[16px] lg:text-[18px] text-[#6D2E88] h-[40px] flex items-center lg:leading-[65px] ">
                      {t("highlighted")}
                    </h3>
                    <div className="flex gap-2 lg:gap-[18px] mt-2">
                      {item.tags?.map((tag) => sliderTags(tag[locale]))}
                    </div>
                  </div>

                  {/* project details */}
                  <div className="w-full lg:w-1/2 min-w-[350px]">
                    <h2
                      className="font-[590] text-[#010140]  text-[24px] lg:text-[40px] mt-4 lg:mt-6 mb-4 lg:mb-5 leading-[32px] lg:leading-[48px]"
                      dangerouslySetInnerHTML={{ __html: item.projectName }}
                    />
                    <p className=" leading-[20px] lg:leading-[26px] font-[510] text-[#010140]  lg:text-[18px] ">
                      {item.title}
                    </p>
                  </div>

                  {/* discover more  */}
                  <Link href={`./projects/${item.slug}`}>
                    <div className="flex flex-nowrap justify-start mr-auto items-center mx-auto mt-7  lg:mt-6 cursor-pointer w-full ">
                      <CustomBtnComponent
                        href={`./projects/${item.slug}`}
                        txt={t("discover")}
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default HomePageSlider;
