import React from "react";

export default function LeftArrow() {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.26029 9.64687C5.383 9.64687 5.51515 9.64687 5.63787 9.64687C14.2657 9.64687 15.6762 9.64687 24.3041 9.64687C25.2575 9.64687 25.8616 10.0767 25.9843 10.8417C26.1071 11.5724 25.4935 12.2945 24.6817 12.3718C24.5212 12.389 24.3513 12.3804 24.1908 12.3804C15.5912 12.3804 14.228 12.3804 5.62843 12.3804C5.50572 12.3804 5.383 12.3804 5.2414 12.3804C5.27916 12.5438 5.40188 12.5953 5.48684 12.6813C8.00723 14.9851 10.5371 17.2802 13.0669 19.584C13.9353 20.3748 13.6616 21.5869 12.5383 21.9221C11.9341 22.1027 11.4055 21.9651 10.9524 21.5525C10.2161 20.882 9.48926 20.2201 8.75297 19.5496C6.03434 17.0739 3.31571 14.5982 0.597081 12.1226C-0.205291 11.3919 -0.195851 10.6354 0.606521 9.90475C4.04256 6.77576 7.47861 3.64677 10.9147 0.517781C11.3489 0.122359 11.8397 -0.10114 12.4722 0.0449932C13.605 0.311473 14.0109 1.54072 13.2085 2.31437C12.661 2.84733 12.0757 3.3545 11.5094 3.87027C9.4987 5.70125 7.48805 7.53222 5.4774 9.3546C5.39244 9.43197 5.2886 9.48354 5.19421 9.54372C5.21309 9.5781 5.24141 9.61249 5.26029 9.64687Z"
        fill="#F6F6F6"
      />
    </svg>
  );
}
