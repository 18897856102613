import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
export default function index({ title, white = false, dark = false }) {
  const router = useRouter();
  const [lang, setLang] = useState(router.locale);
  useEffect(() => {
    setLang(router.locale);
  }, [router.locale]);
  return (
    <div className="text-center" key={lang + "title"}>
      <p
        className={`
        ${white == true ? "text-white" : "text-[#010140]"}
        ${lang == "ar" && "mb-2"}
        font-[590] text-[32px] mds:text-[48px] leading-[56px] mds:leading-[65px] text-[#010140]`}
      >
        {title}
      </p>
      <hr
        className={`h-[5px] border-none rounded-[22.5px] w-[138px] m-auto ${
          dark == true
            ? "bg-[#010140]"
            : "bg-gradient-to-r from-[#2EBBFF] via-[#FE17FF] to-[#6232EA]"
        }`}
      />
    </div>
  );
}
