import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow } from "swiper";
import { AnimatePresence, motion } from "framer-motion";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Link from "next/link";
import Image from "next/image";
import Arrow from "../../assets/scroll/Arrow";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import CustomBtn from "../../btn/buttonCustom/index";
import SectionTitle from "../../SectionTitle/index";
export default function OurBlog({ allBlogs }) {
  const ref = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  const router = useRouter();
  const [lang, setLang] = useState(router.locale);

  useEffect(() => {
    setLang(router.locale);
  }, [router.locale]);
  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };
  const coverFlowSpecs = {
    rotate: 80,
    depth: 300,
    modifier: 0.6,
    slideShadows: false,
  };
  const t = useTranslations("blogs");
  return (
    <div className="relative pt-[60px]" id="ourBlog">
      <div className="mx-auto max-w-[90rem]  px-4 pb-10 sm:px-6  lg:px-0">
        <SectionTitle title={t("ourBlog")} />
      </div>
      <div
        className="relative bg-[#F5F5F5] pt-5 lg:bg-transparent  rounded-t-[10px]"
        id="ourBlogs"
      >
        <AnimatePresence>
          <Swiper
            onSlideChange={handleSlideChange}
            effect={"coverflow"}
            key={lang}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            shortSwipes={false}
            a11y={false}
            breakpoints={{
              640: {
                slidesPerView: 2,
                loop: false,
                rotate: 0,
              },
              768: {
                slidesPerView: 2,
                loop: false,
              },
              1024: {
                slidesPerView: 3,
              },
              1390: {
                slidesPerView: 3,
              },
              1440: {
                slidesPerView: 3,
              },
            }}
            coverflowEffect={coverFlowSpecs}
            pagination={{ clickable: true }}
            speed={1500}
            parallax={true}
            slidesPerView={"auto"}
            modules={[EffectCoverflow, Pagination, Navigation]}
            navigation={{
              nextEl: ".swiper-button-next-4",
              prevEl: ".swiper-button-prev-4",
            }}
            className="swiper absolute -z-50 h-[550px] lg:h-[600px]"
          >
            {allBlogs?.map((item, realIndex, virtualIndex, index) => (
              <SwiperSlide key={index} virtualIndex={virtualIndex}>
                <article ref={ref} className="flex justify-center ">
                  <div className="flex justify-center items-center overflow-hidden rounded-[10px]">
                    <figure className="relative xl:h-[320px] block xlg:w-[380px] xl:w-[480px] lg:w-[400px] lg:h-[280px] h-[202px] w-[300px] cursor-pointer">
                      <Link href={`blogs/${item?._id}`}>
                        <Image
                          src={item?.image}
                          alt="blog datailed page"
                          width={500}
                          className="object-cover"
                          height={500}
                        />
                      </Link>
                    </figure>
                  </div>
                </article>
                <div className="xl:w-full w-11/12 mx-auto ">
                  {currentIndex == realIndex ? (
                    <motion.div
                      className={`pt-[48px]  -z-10  text-center `}
                      initial={{ height: 0, opacity: 0, y: -150 }}
                      animate={{ height: "auto", opacity: 1, y: 0 }}
                      transition={{
                        duration: 0.5,
                        delay: 0.2,
                        stiffness: 100,
                      }}
                    >
                      <p className="text-[#010140] line-clamp-2 pb-[5px] lg:pb-[8px] text-center  capitalize font-bold text-[18px] lg:text-[24px] overflow-hidden">
                        {item?.title}
                      </p>
                      <div className="">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                          className="text-[#010140] h-[120px]  text-center text-[16px] lg:text-[20px] overflow-hidden"
                        />
                      </div>
                    </motion.div>
                  ) : null}
                </div>
              </SwiperSlide>
            ))}
            <div className="hidden lg:flex items-center absolute w-full z-50 justify-center -translate-y-[30%] h-[150px] bottom-0 ">
              <div
                className="lg:w-7/12 h-20 md:w-9/12 w-full absolute items-center justify-between"
                key={lang + "blog"}
              >
                <div
                  className={` ${lang == "ar"
                    ? "swiper-button-next-4"
                    : "swiper-button-prev-4"
                    }  group absolute -scale-x-100 left-[3%] flex h-14 w-14 cursor-pointer items-center justify-center rounded-[8px]`}
                >
                  <Arrow />
                </div>
                <div
                  className={`${lang == "ar"
                    ? "swiper-button-prev-4"
                    : "swiper-button-next-4"
                    } group absolute right-[3%] flex h-14 w-14 cursor-pointer  items-center justify-center rounded-[8px]`}
                >
                  <Arrow />
                </div>
              </div>
            </div>
            <div className="swiper-pagination-4 relative"></div>
            {/* <div className="swiper-pagination-4"></div> */}
          </Swiper>
          <div className="flex justify-center pb-3">
            <CustomBtn txt={t("viewBlogs")} href={"/blogs"} />
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
}
