/**
 * Get rid of relict from using next-pwa
 */
function ServiceWorkerUnregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      if (!registrations.length) {
        console.info("✅ No serviceWorker registrations found.");
        return;
      }
      for (let registration of registrations) {
        registration.unregister().then((boolean) => {
          // result of the unregister
          if (boolean) {
            console.info("✅ Successfully unregistered");
          } else {
            console.error("🔥 Failed to unregister");
          }
        });
      }

      // reload the whole page
      if (undefined !== window) {
        window.location.reload();
      }
    });
  } else {
    console.info("✅ Service worker is not supported in this browser");
  }
}

export default ServiceWorkerUnregister;
