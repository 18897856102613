import { useState, useEffect } from "react";
import BackgroundColor from "./BackgroundColor";
import Responsive_logo from "./Responsive_logo";
import { motion, useAnimationControls } from "framer-motion";
import Images from "./Images";
import TitleAnimition from "./TitleAnimition";
import { useRouter } from "next/router";
export default function Logo({ animation }) {
  const router = useRouter();
  const animationSlider = animation?.length;
  const [counter, setCounter] = useState(1);
  const [startAnimition, setstartAnimition] = useState(true);
  const [changeImage, setChangeImage] = useState(0);
  const screen = { desktop: "desktop", Responsive: "responsive" };
  const scale = useAnimationControls();
  const rotate = useAnimationControls();
  const directions = useAnimationControls();

  const { title, buttonName, buttonLink } = animation?.[changeImage];
  const change = () => {
    setTimeout(() => {
      if (counter < animationSlider) {
        setChangeImage(changeImage + 1);
        setCounter(counter + 1);
      } else {
        setChangeImage(0);
        setCounter(1);
      }
      setstartAnimition(startAnimition ? false : true);
    }, 2000);
  };
  useEffect(() => {
    scale.start((i) => ({
      opacity: 1,
      scale: 1,
      rotate: [180, 0],
    }));
    rotate.start((i) => ({
      scale: [1, 1, 1, 1, 1],
      rotate: [180, 0],
    }));
    directions.start();
  }, [startAnimition]);
  const transition = {
    duration: 1.5,
    ease: "easeInOut",
    times: [0, 0, 0, 0, 0],
    repeat: 1,
    repeatType: "reverse",
    repeatDelay: 2,
  };
  return (
    <div className=" mds:h-[100vh] xlg:h-[auto] h-[100vh] ">
      <div
        className={` relative max-w-[90rem] w-[100%]  xlg:w-[80%] mds:pt-[34rem]  flex xlg:justify-between h-[600px] flex-col justify-end  xlg:pt-[0px] xlg:m-auto mds:h-[500px]  items-center ${router.locale == "en" ? "xlg:flex-row-reverse " : "xlg:flex-row "
          }`}
        dir="ltr"
        id="AboutUs"
      >
        <div className={`absolute max-w-[500px] w-[100%] top-0 -z-50`}>
          <BackgroundColor
            changeImage={changeImage}
            screen={screen.Responsive}
            className={`mds:hidden block`}
          />
          <BackgroundColor
            changeImage={changeImage}
            screen={screen.desktop}
            className={`mds:block hidden`}
          />
        </div>

        <motion.div
          initial={{ opacity: 0, scale: 0.1 }}
          animate={scale}
          transition={transition}
          onAnimationComplete={change}
        >
          <div className=" rounded-[80px]  h-[209.58px] w-[214.79px] mds:h-[322px] mds:w-[330px]  relative mds:rounded-[132px]  Logo">
            <div className=" h-[196.58px] w-[200.79px] mds:h-[309px] mds:w-[316px]  bg-[#F6F6F6] opacity-[60%] rounded-[80px] mds:rounded-[126px] absolute top-0 left-0"></div>
            <Images changeImage={changeImage} animation={animation} />
            <Responsive_logo changeImage={changeImage} animation={animation} />
          </div>
        </motion.div>

        <TitleAnimition
          changeImage={changeImage}
          title={title}
          buttonText={buttonName}
          buttonLink={buttonLink}
        />
      </div>
    </div>
  );
}
