import React, { useState } from "react";
import Image from "next/image";
import ImageF from "./ImageF";
export default function Images({ changeImage, animation }) {
  const { bottomLeft, bottomRight, projectLogo, topLeft, topRight } =
    animation?.[changeImage];
  // animation[changeImage];
  const [animationCycles, setAnimationCycles] = useState(0);

  const image = (className, height, width, src, { start }, { end }) => {
    return (
      <div className={className}>
        <motion.div
          key={changeImage}
          initial={{ opacity: 0, scale: 0.5, x: start.x, y: start.y }}
          animate={{ opacity: 1, scale: 1, x: end.x, y: end.y }}
          transition={{
            duration: 1.5,
            repeatType: "mirror",
            repeatDelay: 2,
            repeat: animationCycles < 2 ? 1 : 0,
          }}
        >
          <motion.div
            className="w-[100%] flex justify-center"
            animate={{
              scale: [0, 1],
              rotate: [180, 0],
            }}
            transition={{
              duration: 1.5,
              ease: "easeInOut",
              times: [0, 0, 0, 0, 0],

              repeatType: "mirror",
              repeatDelay: 2,
            }}
          >
            <>
              <Image
                src={src}
                height={height}
                width={width}
                alt="ascot image"
              />
            </>
          </motion.div>
        </motion.div>
      </div>
    );
  };
  return (
    <div className="mds:block hidden">
      <div className={`absolute left-[17%] top-[30%]`}>
        <Image
          height={"110px"}
          width={"220px"}
          src={projectLogo}
          alt="ascot images"
          className="object-scale-down"
        />
      </div>
      <div className="mds:block hidden">
        <ImageF
          changeImage={changeImage}
          className={"absolute"}
          src={topLeft}
          position={{ start: { x: 100, y: 50 }, end: { x: -90, y: -50 } }}
        />
        <ImageF
          changeImage={changeImage}
          className={"absolute"}
          src={topRight}
          position={{ start: { x: 0, y: 0 }, end: { x: 200, y: -50 } }}
        />
        <ImageF
          changeImage={changeImage}
          className={"absolute"}
          src={bottomRight}
          position={{ start: { x: 100, y: 50 }, end: { x: 200, y: 170 } }}
        />
        <ImageF
          changeImage={changeImage}
          className={"absolute"}
          src={bottomLeft}
          position={{ start: { x: 100, y: 50 }, end: { x: -120, y: 140 } }}
        />
      </div>
    </div>
  );
}
