import React from "react";
import Image from "next/image";
import { motion } from "framer-motion";
const ImageF = ({ className, src, position, changeImage }) => {
  const { start, end } = position;

  return (
    <div className={className}>
      <motion.div
        key={changeImage}
        initial={{ opacity: 0, scale: 0.5, x: start.x, y: start.y }}
        animate={{ opacity: 1, scale: 1, x: end.x, y: end.y }}
        transition={{
          duration: 1.5,
          repeatType: "mirror",
          repeatDelay: 2,
          repeat: 1,
        }}
      >
        <motion.div
          animate={{
            scale: [0, 1],
            rotate: [180, 0],
          }}
          transition={{
            duration: 1.5,
            ease: "easeInOut",
            times: [0, 0, 0, 0, 0],
            repeatType: "mirror",
            repeatDelay: 2,
          }}
        >
          <div className=" relative h-[110px] w-[110px] mds:h-[200px] mds:w-[200px] object-scale-down">
            <Image layout="fill" src={src} alt="ascot image" />
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ImageF;
