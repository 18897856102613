import React from "react";
import Button from "../../components/btn/buttonCustom/index";
import { useTranslations } from "next-intl";
export default function OurVision() {
  const t = useTranslations("Home.Ultimate");
  return (
    <div className="mb-[56px] xlg:pt-[10px] ">
      <div className="flex flex-col items-center justify-center max-w-[972px] w-[80%] m-auto">
        <p className=" font-[590] text-[32px] mds:text-[48px] leading-[56px] mds:leading-[65px] text-[#010140] ">
          {t("title")}
        </p>
        <hr className="mb-[15px]  w-[138px] bottomborder"></hr>
        <p className=" text-center text-[18px] font-[400] leading-[28px]  text-[#010140] mb-[24px]">
          {t("description")}
        </p>

        <Button txt={t("button")} href="/about_us" />
      </div>
    </div>
  );
}
