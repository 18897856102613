import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Arrow from "../../assets/scroll/Arrow";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";

export default function Scroll() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const router = useRouter();
  const [lang, setLang] = useState(router.locale);
  const t = useTranslations("homepageProgress");
  const footerRef = useRef(null);

  const handleSectionClick = (sectionId) => {
    window.onscroll = () => { };
    const navbarHeight = 100; // set the height of your navbar here
    const sectionElement = document.getElementById(sectionId);
    const sectionPosition = sectionElement.offsetTop - navbarHeight;
    window.scrollTo({ top: sectionPosition, behavior: "smooth" });
  };

  useEffect(() => {
    // Update the maxScroll value considering the footer height
    const footerHeight = footerRef.current ? footerRef.current.offsetHeight : 0;
    setMaxScroll(
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight +
      footerHeight
    );

    window.addEventListener("scroll", handleScroll);
    setLang(router.locale);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [router.locale]);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  const borderWidth = (scrollPosition / maxScroll) * 80;

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="relative hidden mds:hidden lg:block xl:block z-20">
        <div className="bg-white fixed top-[70px] left-0 w-full z-10">
          <div className="bg-gray-200 bg-opacity-80 rounded-tr-3xl rounded-tl-3xl w-full">
            <div className=" gap-14 flex items-center ">
              <div className="w-[13.3rem] border-b-[3.5px] border-[#FE17FF] bg-opacity-80 rounded-tl-3xl   flex bg-[#F6F6F6] px-4 py-3">
                <div
                  className={`mx-auto cursor-pointer 
                ${lang == "ar" ? "-scale-x-100" : null}
                `}
                  onClick={handleClick}
                >
                  <motion.div
                    animate={{ rotate: scrollPosition > 0 ? -90 : 0 }}
                    transition={{
                      duration: 0.5,
                      ease: "easeInOut",
                    }}
                  >
                    <Arrow />
                  </motion.div>
                </div>
              </div>
              <div
                onClick={() => handleSectionClick("AboutUs")}
                className="text-[14px] font-[510] text-[#010140] leading-[16.71px] hover:cursor-pointer"
              >
                {t("aboutUs")}
              </div>
              <div
                onClick={() => handleSectionClick("Products")}
                className="text-[14px] font-[510] text-[#010140] leading-[16.71px] hover:cursor-pointer"
              >
                {t("services")}
              </div>
              <div
                onClick={() => handleSectionClick("WhatsDifferent")}
                className="text-[14px] font-[510] text-[#010140] leading-[16.71px] hover:cursor-pointer"
              >
                {t("different")}
              </div>
              <div
                onClick={() => handleSectionClick("ourBlog")}
                className="text-[14px] font-[510] text-[#010140] leading-[16.71px] hover:cursor-pointer"
              >
                {t("ourBlog")}
              </div>
              <div
                onClick={() => handleSectionClick("Projects")}
                className="text-[14px] font-[510] text-[#010140] leading-[16.71px] hover:cursor-pointer"
              >
                {t("projects")}
              </div>
              <div
                onClick={() => handleSectionClick("contactUs")}
                className="text-[14px] font-[510] text-[#010140] leading-[16.71px] hover:cursor-pointer"
              >
                {t("contactUs")}
              </div>
            </div>
            <motion.div
              className="h-[3px] -mt-[3px] z w-3 bg-gradient-to-r from-[#2EBBFF] via-[#FE17FF] to-[#6232EA]"
              style={{ width: `${Math.min(borderWidth)}%` }}
              animate={{ width: `${Math.min(borderWidth, 100)}%` }}
              transition={{ duration: 0.1 }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
