import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
export default function BackgroundColor({ changeImage, screen, className }) {
  const { locale } = useRouter();
  console.log(changeImage)
  const postion = {
    firstColor: {
      desktop: { start: { x: 100, y: -300 }, end: { x: -50, y: -400 } },
      responsive: { start: { x: 50, y: -210 }, end: { x: -10, y: -250 } },
    },
    secoundColor: {
      desktop: { start: { x: 150, y: -300 }, end: { x: 200, y: -400 } },
      responsive: { start: { x: 110, y: -210 }, end: { x: 120, y: -250 } },
    },
    thirdColor: {
      desktop: { start: { x: 50, y: -150 }, end: { x: -50, y: -150 } },
      responsive: { start: { x: 50, y: -150 }, end: { x: 0, y: -100 } },
    },
    fourthColor: {
      desktop: { start: { x: 200, y: -250 }, end: { x: 200, y: -150 } },
      responsive: { start: { x: 130, y: -100 }, end: { x: 120, y: -50 } },
    },
    fifthColor: {
      desktop: { start: { x: 150, y: -150 }, end: { x: 100, y: -50 } },
      responsive: { start: { x: 50, y: -80 }, end: { x: 50, y: -50 } },
    },
  };
  const backgroundColors = (className, { start, end }) => {
    return (
      <motion.div
        key={changeImage}
        initial={{ scale: 1, x: start.x, y: start.y }}
        animate={{
          x: end.x,
          y: end.y,
          scale: [0.6, 1],
        }}
        transition={{
          duration: 1.5,
          ease: "easeInOut",
          repeat: 1,
          repeatType: "reverse",
          repeatDelay: 2,
        }}
      >
        <div className={className} />
      </motion.div>
    );
  };
  return (
    <div
      className={` blur-[30px] absolute  left-[20%]  top-[350px]   ${locale == "en"
          ? "xlg:left-[150px] mds:left-[20%]"
          : "xlg:left-0 mds:left-[20%]"
        } ${className}  xlg:top-[400px]  `}
    >
      {backgroundColors(
        "bg-[#0AF0DD] w-[116.51px] h-[115.86px]  mds:h-[178px] mds:w-[177px] absolute ",
        postion.firstColor[screen]
      )}

      {backgroundColors(
        "bg-[#36A9E1] mds:h-[178px] mds:w-[177px] w-[116.51px] h-[115.86px]  absolute ",
        postion.secoundColor[screen]
      )}

      {backgroundColors(
        "bg-[#951B81] mds:h-[177px] mds:w-[177px]  w-[116.51px] h-[115.86px] opacity-[0.7]   absolute ",
        postion.thirdColor[screen]
      )}
      {backgroundColors(
        "bg-[#BE72F3] mds:h-[228.06px] mds:w-[181px] w-[116.51px] h-[115.86px]  rotate-3	opacity-[0.7]  absolute  ",
        postion.fourthColor[screen]
      )}
      {backgroundColors(
        "bg-[#36A9E1]   mds:h-[120px] mds:w-[119px] w-[116.51px] h-[115.86px]  opacity-[0.7]  absolute  ",
        postion.fifthColor[screen]
      )}
    </div>
  );
}
