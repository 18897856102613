import React, { useState, useEffect, useRef } from "react";
import { motion, useMotionValue } from "framer-motion";
import ArrowCircle from "../../btn/buttonCustom";
import { useTranslations } from "next-intl";
import { useScrollLock } from "./lockScroll";
import { useRouter } from "next/router";
const App = () => {
  const [zoomScale, setZoomScale] = useState(1);
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [windowWidth, setWindowWidth] = useState();

  const { locale } = useRouter();

  const handleWheel = (e) => {
    const sign = e.deltaY > 0 ? 1 : -1;
    const delta = sign * (scale.get() > 20 ? 10 : scale.get() > 10 ? 7 : 1);
    const newScale = scale.get() + delta;
    // Limit zooming to within the range of 1 to 100.
    if (newScale < 1) {
      scale.set(0.99);
      setZoomScale(0.99);
    } else if (newScale > 100) {
      scale.set(100.01);
      setZoomScale(100.01);
    } else {
      setTimeout(() => {
        scale.set(newScale);
        setZoomScale(newScale);
      }, 1);
    }
  };

  function preventDefault(e) {
    e.preventDefault();
    e.stopPropagation();
    handleWheel(e);
    return false;
  }

  const { lockScroll, unlockScroll } = useScrollLock(
    (preventDefault = { preventDefault })
  );

  const t = useTranslations("scrollAnimation");
  const ref = useRef(null);

  const scale = useMotionValue(1.2);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  // track scroll container and set wheel event to body and set isOnScreen
  const trackContainer = () => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect?.bottom <= window.innerHeight && rect?.top > 0) {
      setIsOnScreen(true);
    } else {
      setIsOnScreen(false);
    }

    if (rect?.top <= window.innerHeight && rect?.top > 0) {
      document.documentElement.addEventListener("wheel", handleWheel);
    }
  };

  // set window width
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  // Check if element is on screen initially
  useEffect(() => {
    window.addEventListener("scroll", trackContainer);
    return () => {
      window.removeEventListener("scroll", trackContainer);
    };
  }, []);

  //  disable scroll when the animation on window
  useEffect(() => {
    let unlockTimeout = null;
    if (isOnScreen && zoomScale < 100 && zoomScale > 1) {
      lockScroll();
    } else {
      unlockTimeout = setTimeout(() => {
        unlockScroll();
      }, 500);
    }

    return () => {
      clearTimeout(unlockTimeout);
    };
  }, [isOnScreen, zoomScale]);

  return (
    <>
      <span id="WhatsDifferent" />
      {windowWidth > 1250 && (
        <div
          className={`scrollContainer  relative `}
          ref={ref}
          id="zoomOnScroll"
        >
          <motion.img
            src={"/ScrollAnimation/logo.svg"}
            style={{
              x,
              y,
              scale: scale,
              transition: "all 0.3s ease-in-out",
              translateY: zoomScale * -20 - 84,
              translateX: zoomScale,
            }}
            alt="Ascot logo"
          />
          {/* phone  */}
          <div className="absolute w-full left-[50px] bottom-[-180px] flex justify-end">
            <div
              className="phoneDiv "
              style={{
                transform: `rotate(${(1 - zoomScale / 100) * -65}deg)`,
                opacity: (zoomScale - 50) / 50,
              }}
            >
              <motion.img
                src="/ScrollAnimation/phone.svg"
                alt="ascot services homepage animation"
              />
            </div>
          </div>

          {/* hand  */}
          <div className="absolute w-full  right-[-130px] bottom-[-50px] flex justify-end">
            <div
              className="phoneDiv  origin-bottom-right mr-[30px] w-[600px] xl:w-auto"
              style={{
                transform: `rotate(${(1 - zoomScale / 100) * 65 - 5}deg)`,
                opacity: (zoomScale - 50) / 50,
              }}
            >
              <motion.img
                src="/ScrollAnimation/hand.svg"
                alt="ascot services homepage animation"
              />
            </div>
          </div>
          {/* title  */}

          <div
            className="absolute w-full flex justify-start px-[9%] placeTitle"
            style={{ top: zoomScale > 80 ? "9vw" : 1000 }}
          >
            <div>
              <p dangerouslySetInnerHTML={{ __html: t("title") }}></p>
            </div>
          </div>

          {/* text */}
          <div
            className={`absolute w-full flex justify-start placeText px-[9%] ${
              zoomScale > 80
                ? " top-[calc(9vw+120px)] xl:top-[calc(9vw+154px)]"
                : "top-[1000px]"
            } `}
          >
            <p className="max-w-[554px]">
              {t("text").length > 250
                ? t("text").slice(0, 199) + "..."
                : t("text")}
            </p>
          </div>
          {/* button  */}
          <div
            className="absolute w-full flex justify-start placeButton  px-[9%] z-20 "
            style={{ opacity: zoomScale > 80 ? 100 : 0 }}
          >
            <ArrowCircle dark={false} txt={t("discover")} href={"/services"} />
          </div>

          {/* largest circle  */}
          <div className="absolute w-full flex justify-end bottom-[25px] xl:bottom-[50px] h-[350px] xl:h-[490px] right-[-100px]">
            <div className="circle1   " />
          </div>

          {/* upper circle  */}
          <div className="absolute w-full flex justify-end top-[-140px] xl:top-[-200px]  h-[200px] xl:h-[350px] ">
            <div className="circle2  " />
          </div>
          {/* lower circle  */}
          <div className="circle3" />
          {/* starts  */}
          <div className="absolute  flex justify-end bottom-[-135px] xl:bottom-[35px] h-fit  left-[-100px] right-[-100px]">
            <div className="stars  ">
              <motion.img
                src="/ScrollAnimation/stars.svg"
                style={{
                  opacity: (zoomScale - 50) / 50,
                }}
                alt="ascot services homepage animation"
              />
            </div>
          </div>
        </div>
      )}{" "}
      {windowWidth <= 1250 && (
        <div className=" scrollAnimationSection ">
          <div className="h-[24rem] ">
            {/* phone  */}
            <div
              className={`smallPhone ${
                locale == "en" ? "xsm:px-[13rem] sm:px-[15rem]" : ""
              }`}
            >
              <motion.img
                src="/ScrollAnimation/smallPhone.svg"
                alt="ascot services homepage animation"
              />
            </div>
            {/* hand  */}

            <div
              className={`smallHand 
          ${locale == "ar" ? "px-[10rem]" : ""} `}
            >
              <motion.img
                src="/ScrollAnimation/smallHand.svg"
                alt="ascot services homepage animation"
              />
            </div>
            {/* large circle  */}
            <div className="circle1-small">
              <div />
            </div>
            {/* small circle  */}
            <div className="circle2-small">
              <div />
            </div>
            {/* starts  */}
            <div
              className={`stars-small
           ${locale == "en" ? "px-[90px]" : " px-10"}
          `}
            >
              <motion.img
                src="/ScrollAnimation/smallStars.svg"
                alt="ascot services homepage animation"
              />
            </div>
          </div>

          <div className="flex flex-col md:max-w-[50%]">
            {/* title  */}
            <div>
              <p
                className="placeTitle-small"
                dangerouslySetInnerHTML={{ __html: t("title") }}
              />
              <hr className="h-[5px] border-0 rounded-full mt-[6px] bg-[#010140] w-[138px]" />
            </div>
            {/* text */}
            <p className="placeText-small max-w-[35rem]">
              {t("text").length > 250
                ? t("text").slice(0, 199) + "..."
                : t("text")}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
