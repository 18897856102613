import RightArrow from "../../assets/products/RightArrow";
import LeftArrow from "../../assets/products/LeftArrow";
import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { AnimatePresence, motion } from "framer-motion";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

export default function index({ products, t }) {
  const router = useRouter();
  const [lang, setLang] = useState(router.locale);

  useEffect(() => {
    setLang(router.locale);
  }, [router.locale]);

  const swiperKey = `${lang}-swiper`;
  const ref = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

  return (
    <div className=" mt-5 mb-3">
      <div className=" transform  duration-300 ease-in-out">
        {/* <!-- Slider --> */}
        <AnimatePresence>
          <Swiper
            onSlideChange={handleSlideChange}
            slidesPerView={"auto"}
            key={swiperKey}
            centeredSlides={true}
            spaceBetween={30}
            loop={true}
            grabCursor={true}
            speed={1000}
            pagination={{ clickable: true }}
            parallax={true}
            modules={[Pagination, Navigation]}
            navigation={{
              nextEl: ".swiper-button-next-4",
              prevEl: ".swiper-button-prev-4",
            }}
            className="swiper max-w-[75rem] h-80 "
          >
            {products?.map((item, realIndex, index) => (
              <>
                <SwiperSlide virtualIndex={index}>
                  <Link href={`services/${item._id}`}>
                    <div
                      className={`w-[372px] bg-gradient-to-br from-[#5f5f86] via-[#251f59] to-[#5f5f86] bg-opacity-10 rounded-[10px] p-[2px] transform duration-500 ease-in-out ${currentIndex == realIndex
                        ? "h-[237px]"
                        : "h-[178px] mt-7"
                        }`}
                    >
                      <div className=" h-full rounded-[8px] bg-[#010140]">
                        <div className=" h-full rounded-[8px] glassProduct">
                          <motion.div
                            className={` h-full flex justify-center items-center`}
                          >
                            <article ref={ref} className="flex justify-center">
                              <div className="flex justify-center items-center rounded-[10px] ">
                                <figure className=" flex justify-start items-center flex-col relative cursor-pointer">
                                  <div className="flex justify-start items-center flex-col">
                                    <Image
                                      src={item?.icon}
                                      width={"65px"}
                                      height={"65px"}
                                      alt="single product"
                                      className="brightness-0 invert"
                                    />
                                    <p className="text-white font-[274] text-center capitalize mt-5 text-[24px]">
                                      {item?.iconTitle}
                                    </p>
                                  </div>
                                  {currentIndex == realIndex ? (
                                    <motion.div
                                      className={`px-8 text-center text-white transform ease-in-out`}
                                      initial={{ height: 0, opacity: 0 }}
                                      animate={{ height: "auto", opacity: 1 }}
                                      // exit={{ height: 0, opacity: 0 }}
                                      transition={{
                                        duration: 0.4,
                                        delay: 0,
                                        // stiffness: 100,
                                      }}
                                    >
                                      <div className="product_btn mt-2 btn cursor-pointer flex justify-center  m-auto  items-center">
                                        <button className="">
                                          {t("request_quote")}
                                        </button>
                                      </div>
                                    </motion.div>
                                  ) : null}
                                </figure>
                              </div>
                            </article>
                          </motion.div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              </>
            ))}
          </Swiper>
          <div className="flex justify-center mx-auto   items-center w-11/12 relative  ">
            <div className="absolute bottom-6 flex w-full  items-center justify-between  ">
              <div
                className={`${lang == "ar" ? "swiper-button-next-4" : "swiper-button-prev-4"
                  } group z-10 absolute left-4  flex h-10 cursor-pointer items-center justify-center font-bold rounded-[8px] text-[18px] `}
              >
                <LeftArrow />
              </div>
              <div
                className={`${lang == "ar" ? "swiper-button-prev-4" : "swiper-button-next-4"
                  } group z-10 absolute right-4 flex h-10 cursor-pointer items-center justify-center rounded-[8px]  text-[18px]  font-bold`}
              >
                <RightArrow />
              </div>
            </div>
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
}
