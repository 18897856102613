import { useTranslations } from "next-intl";
import CustomBtn from "../btn/buttonCustom/index";
import SectionTitle from "../SectionTitle";
export default function index() {
  const t = useTranslations("contactUsSection");

  return (
    <div
      id="contactUs"
      className=" mt-[3rem] mb-[70px] flex flex-col items-center justify-center m-auto"
    >
      <div className="mb-9">
        <SectionTitle title={t("title")} />
      </div>
      <p
        className="mb-10 lg:mb-14  text-center font-[400] leading-[20px] text-[16px] md:text-[18px] md:leading-[28px]   "
        dangerouslySetInnerHTML={{ __html: t("text") }}
      />

      <CustomBtn txt={t("title")} href="/contact" />
    </div>
  );
}
